import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

/*----- Components -----*/
import { Form } from 'antd';
import { ItemCheckoutContainer } from '../../../../../components/containers/CheckoutContainer';
import ArrivalForm from '../../../../commons/checkout/ArrivalForm';
import ReservationCheckout from '../../../../commons/checkout/ReservationCheckout';
import Text from '../../../../../components/text/Text';

/*----- State -----*/
import { transferData } from '../../../selectors/showTransferSelector';
import { addToCart } from '../../../../cart/actions/cartActions';
import { setNotification } from '../../../../home/HomeActions';
/*----- Service -----*/
import {
	addCheckoutTransfer,
	getCheckoutTransfer
} from '../../../../../services/TransferCheckoutService';
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit,
	mixFields
} from '../../../../commons/checkout/CheckoutUtils';
import { addToCartHandler } from '../../../../../utilities/utils';
import { getSearchQuery } from '../../../../../services/SearchService';

const TransferForm = ({ history, form, transfer, addToCart, setNotification }) => {
	const [continueReservation, setContinueReservation] = useState();
	const { t } = useTranslation();
	let id = getId(history);
	const searchQuery = getSearchQuery();
	const dataCheckout = getCheckoutTransfer(id);
	let passengersRequired = transfer.rate.amount_details.occupancy.required;
	let passengers = countPassengers(passengersRequired);
	const isMix = transfer.info.kind === 'mix';

	const parseCheckoutInfo = data => ({
		observation: data.observation || '',
		information_type: transfer.info.kind,
		...(transfer.info.kind !== 'out' && {
			reservation_date_in: moment(searchQuery.date_in).format('DD/MM/YYYY')
		}),
		...(transfer.info.kind !== 'in' && {
			reservation_date_out: moment(searchQuery.date_out).format('DD/MM/YYYY')
		}),
		...mixFields(isMix, data, transfer.info.kind)
	});

	const setPassengersParsed = data => {
		let checkoutInfo = parseCheckoutInfo(data);
		checkoutInfo.passengers = loadPassengers(data, passengers);
		addCheckoutTransfer(id, checkoutInfo);
	};

	const transferCartHandler = data => {
		const parsedData = parseCheckoutInfo(data);
		addToCartHandler(
			parsedData,
			transfer,
			addToCart,
			history,
			() => addCheckoutTransfer(id, parsedData),
			() =>
				setNotification({
					msg: t('general.transferAddCart'),
					type: 'transfer'
				})
		);
	};

	return (
		<Form>
			<ItemCheckoutContainer>
				<Text content={t('checkout.transfers.additional')} />

				{isMix ? (
					<>
						<ArrivalForm
							form={form}
							dataCheckout={dataCheckout && dataCheckout['in']}
							transferKind="in"
							isMix
						/>
						<ArrivalForm
							form={form}
							dataCheckout={dataCheckout && dataCheckout['out']}
							transferKind="out"
							isMix
						/>
					</>
				) : (
					<ArrivalForm
						form={form}
						dataCheckout={dataCheckout}
						transferKind={transfer.info.kind}
					/>
				)}
			</ItemCheckoutContainer>
			<ReservationCheckout
				continueReservation={continueReservation}
				form={form}
				id={id}
				checkoutHandleSubmit={checkoutHandleSubmit}
				addToCartHandler={formData => transferCartHandler(formData)}
				setContinueReservation={setContinueReservation}
				passengersRequired={passengersRequired}
				setPassengersParsed={setPassengersParsed}
				passengers={passengers}
				service="transfers"
				dataCheckout={dataCheckout && dataCheckout[transfer.info.kind]}
				countPassengers={passengers}
			/>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		transfer: transferData(state)
	};
};
const mapDispatchToProps = dispatch => {
	return {
		addToCart: payload => addToCart(dispatch, payload),
		setNotification: payload => setNotification(dispatch, payload)
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'checkout_transfer_form' })(withRouter(TransferForm)));
