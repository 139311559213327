import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReservationPanel from './ReservationPanel';
import ReservationPanelDetail from './ReservationPanelDetail';

/*----- Selectors -----*/
import { hotelRoom } from '../../modules/hotels/selectors/hotelRoomSelector';

/*----- Utils -----*/
import { getMinorRoom } from '../../modules/commons/detail/hotels/HotelRooms/hotelRoomsUtils';

/*----- Actions -----*/
import { selectRoom } from '../../modules/hotels/actions/hotelRoomActions';
import { setNotification } from '../../modules/home/HomeActions';
import {
	getId,
	parseCartHotelData
} from '../../modules/commons/checkout/CheckoutUtils';
import { addToCart } from '../../modules/cart/actions/cartActions';
import { getPackageHotel } from '../../services/PackagesCheckoutService';
import Loading from '../../modules/login/confirmRegister/Loading';

const ReservationHotelCard = ({
	history,
	showData,
	hotelRoom,
	selectRoom,
	getInformation,
	getDetailCheckout,
	addCheckout,
	panelTitle,
	service,
	addToCart,
	setNotification
}) => {
	const { t } = useTranslation();
	const isPackage = service === 'packages';
	const defaultRate = getMinorRoom(showData.rates || [showData.rate]);
	const packageHotel = getPackageHotel();
	const [initialItemsDetails, setInitialItemsDetails] = useState(null);
	const [itemsDetails, setItemsDetails] = useState(initialItemsDetails);
	const [cartItem, setCartItem] = useState(
		parseCartHotelData(showData, hotelRoom.item_id, isPackage, initialItemsDetails)
	);

	const [dataInformation, setDataInformation] = useState(null);
	const [amount, setAmount] = useState(defaultRate.amount_with_taxes);

	useEffect(() => {
		setDataInformation(
			getInformation(
				isPackage ? packageHotel : showData,
				isPackage ? packageHotel?.item_id : hotelRoom.item_id,
				isPackage
			)
		);
	}, [isPackage, packageHotel, showData, getInformation, hotelRoom]);

	useEffect(() => {
		if (dataInformation) {
			setInitialItemsDetails(
				getDetailCheckout(
					dataInformation,
					!isPackage ? ['passengers', 'nights'] : undefined
				)
			);
		}
	}, [getDetailCheckout, dataInformation, isPackage]);

	const id = getId(history);

	const [textButton, setTextButton] = useState(t('general.seeRooms'));
	const [textReserve, setTextReserve] = useState(t('checkout.totalPriceSince'));
	useEffect(() => {
		if (hotelRoom.amount_with_taxes && dataInformation) {
			setTextButton(t('general.reserve'));

			if (!isPackage) setItemsDetails(getDetailCheckout(dataInformation));
			setAmount(hotelRoom.amount_with_taxes);
			setTextReserve(t('checkout.totalPrice'));
			setCartItem(
				parseCartHotelData(
					showData,
					hotelRoom.item_id,
					isPackage,
					getDetailCheckout(dataInformation)
				)
			);
			if (isPackage)
				addCheckout(id, {
					item_id: hotelRoom.item_id,
					item_details: itemsDetails
				});
		}
	}, [
		defaultRate,
		dataInformation,
		hotelRoom,
		getDetailCheckout,
		getInformation,
		isPackage,
		addCheckout,
		id,
		itemsDetails,
		t,
		setCartItem,
		showData
	]);

	if (!dataInformation || !initialItemsDetails || !itemsDetails) {
		return <Loading />;
	}
	return (
		<ReservationPanel
			panelTitle={panelTitle}
			currency={defaultRate.currency}
			amount={amount}
			textTotal={textReserve}
			dateLabel={t('general.date')}
			dateFrom={dataInformation.date_in}
			dateTo={dataInformation.date_out}
			textButton={textButton}
			onClick={() => {
				if (hotelRoom.amount) {
					addCheckout(id, {
						item_id: hotelRoom.item_id
					});
					history.push(`/${service}/${id}/checkout`);
				} else {
					selectRoom();
				}
			}}
			addToCart={!isPackage && hotelRoom.amount && addToCart}
			cartItem={hotelRoom.amount && cartItem}
			setNotification={() =>
				setNotification({
					msg: t('general.hotelAddCart'),
					type: 'hotel'
				})
			}
			setCheckout={() =>
				addCheckout(id, {
					item_id: hotelRoom.item_id,
					reservation_date_in: dataInformation.date_in,
					reservation_date_out: dataInformation.date_out
				})
			}
		>
			<ReservationPanelDetail items={itemsDetails} />
		</ReservationPanel>
	);
};

const mapStateToProps = state => {
	return {
		hotelRoom: hotelRoom(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		selectRoom: payload => selectRoom(dispatch, payload),
		addToCart: payload => addToCart(dispatch, payload),
		setNotification: payload => setNotification(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(ReservationHotelCard));
