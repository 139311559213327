import React from 'react';
import PropTypes from 'prop-types';
import {
	RowCardContainer,
	ColCardImage,
	ColCardInfo,
	InfoRow,
	IconsRow
} from './styles';
import { Col } from 'antd';
import DetailServices from '../../details/DetailServices';
import Price from './Price';
import DefaultImage from '../../../assets/images/DefaultCardImage.svg';

const SaleCard = ({
	price,
	currency,
	imageUrl,
	destination,
	onClick,
	iconServices,
	action,
	withImage,
	cardInformation,
	titlePrice,
	withoutIcons = false,
	handlerClickCard,
	category
}) => {
	return (
		<RowCardContainer type="flex" onClick={handlerClickCard}>
			{withImage && (
				<ColCardImage
					xs={24}
					sm={8}
					image_url={imageUrl ? imageUrl : DefaultImage}
				/>
			)}

			<ColCardInfo xs={24} sm={withImage ? 16 : 24}>
				<InfoRow type="flex">
					<Col xs={24} sm={withImage ? 14 : 16}>
						{cardInformation}
					</Col>
					<Col xs={24} sm={withImage ? 10 : 8}>
						<Price
							title={titlePrice}
							action={action}
							destination={destination}
							onClick={onClick}
							price={price}
							currency={currency}
						/>
					</Col>
				</InfoRow>

				{!withoutIcons && category !== 'activities' && (
					<IconsRow>
						<DetailServices iconServices={iconServices} />
					</IconsRow>
				)}
			</ColCardInfo>
		</RowCardContainer>
	);
};

export default SaleCard;

SaleCard.defaultProps = {
	withImage: true,
	currency: 'USD'
};

SaleCard.propTypes = {
	price: PropTypes.number.isRequired,
	destination: PropTypes.string.isRequired,
	imageUrl: PropTypes.string,
	withImage: PropTypes.bool,
	currency: PropTypes.string
};
