import TransfersIcon from '../../../../../assets/icons/checkout/transfer.svg';
import ActivityIcon from '../../../../../assets/icons/services/map.svg';
import PersonIcon from '../../../../../assets/icons/custom_icons/user.svg';
import { getPassengerInformation } from '../../../../hotels/views/checkout/section/detailsItemsHotel';
import { useTranslation } from 'react-i18next';

const DetailsItemsPackages = ({ withHotel = false, search }) => {
	const { t } = useTranslation();
	return detailsItemsPackages(withHotel, search, t);
};

const detailsItemsPackages = (withHotel, search, t) => [
	{ icon: TransfersIcon, text: t('general.transfer') },
	{ icon: ActivityIcon, text: t('general.activity') },
	{
		icon: PersonIcon,
		text: withHotel
			? getPassengerInformation(search).passengers
			: getPassengerInformation(search).passengersText
	}
];

export default DetailsItemsPackages;
