export const getColumnSearchProps = ({
	dataIndex,
	searchText,
	searchedColumn,
	customRender
}) => {
	if (customRender) {
		return;
	} else {
		return {
			render: text => text
		};
	}
};

const statusCodes = ['CX', 'OK', 'PC', 'PE', 'RQ', 'SS', 'UC', 'WL', 'PEC'];

export const createStatusLabels = t => [
	t('general.status.Canceled'),
	t('general.status.Confirmed'),
	t('general.status.InProcessOfCancellation'),
	t('general.status.PendingDoNotRequestServices'),
	t('general.status.Required'),
	t('general.status.Requested'),
	t('general.status.Closed'),
	t('general.status.WaitingList'),
	t('general.status.PendingCancellation')
];

export const createStatusOptions = t =>
	createStatusLabels(t).map((label, index) => ({
		value: statusCodes[index],
		label: label
	}));

export const getStatusCode = (status, t) => {
	const statusLabels = createStatusLabels(t);
	const index = statusLabels.findIndex(
		i => i.toLowerCase() === status.toLowerCase()
	);
	return statusCodes[index] ? statusCodes[index].toLowerCase() : null;
};

export const isCancelable = statusCode =>
	statusCode !== 'CX' && statusCode !== 'PC' && statusCode !== 'PEC';
