/*----- Core -----*/
import React from 'react';

/*----- Components -----*/
import ServiceDetailItem from '../ServiceDetailItem';

/*----- Assets -----*/
import assistanceIcon from '../../assets/icons/services/package/assistance.svg';
import excursionIcon from '../../assets/icons/services/package/excursion.svg';
import hotelIcon from '../../assets/icons/services/package/hotel.svg';
import transferIcon from '../../assets/icons/services/package/tranfer.svg';
import forkIcon from '../../assets/icons/services/package/fork.svg';
import ticketIcon from '../../assets/icons/services/package/ticket.svg';

const getPackagesServices = services => {
	return services.map((service, i) => {
		let serviceItem;
		switch (service) {
			case 'assistance':
				serviceItem = (
					<ServiceDetailItem
						icon={assistanceIcon}
						content={'packages.services.guide'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'guide':
				serviceItem = (
					<ServiceDetailItem
						icon={assistanceIcon}
						content={'packages.services.guide'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'excursions':
				serviceItem = (
					<ServiceDetailItem
						icon={excursionIcon}
						content={'packages.services.excursions'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'walks':
				serviceItem = (
					<ServiceDetailItem
						icon={excursionIcon}
						content={'packages.services.walks'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'hotel':
				serviceItem = (
					<ServiceDetailItem
						icon={hotelIcon}
						content={'packages.services.hotel'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'transfer':
				serviceItem = (
					<ServiceDetailItem
						icon={transferIcon}
						content={'packages.services.transfer'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'lunch':
				serviceItem = (
					<ServiceDetailItem
						icon={forkIcon}
						content={'packages.services.lunch'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'dinner':
				serviceItem = (
					<ServiceDetailItem
						icon={forkIcon}
						content={'packages.services.dinner'}
						key={`${i}-${service}`}
					/>
				);
				break;
			case 'in':
				serviceItem = (
					<ServiceDetailItem
						icon={ticketIcon}
						content={'packages.services.in'}
						key={`${i}-${service}`}
					/>
				);
				break;
			default:
				break;
		}
		return serviceItem;
	});
};

export default getPackagesServices;
