import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col } from 'antd';
import {
	StyledInput,
	StyledDatePicker
} from '../../../../../components/form/styles';
import { SearchBarContainer, StyledSearchSelect } from './styles';
import { getMyReservations } from '../../../actions/myReservationsActions';
import { parseMyReservationsParams } from '../../../../../utilities/queryString';
import { replaceSearch } from '../../../../../components/pagination/SearchUtils';
import { createStatusOptions, getStatusCode } from './utils';
import SearchSelect from '../../../../../components/form/SearchSelect';

const SearchBar = ({ getMyReservations, history }) => {
	const { t } = useTranslation();
	const statusOptions = createStatusOptions(t);

	const [searchValues, setSearchValues] = useState({
		id: null,
		from: null,
		to: null,
		pax_name: null,
		emission_date: null,
		status_code: null
	});

	const handleInputChange = (field, value) => {
		setSearchValues(prev => ({
			...prev,
			[field]: value
		}));
	};

	const handleStatusChange = value => {
		setSearchValues(prev => ({
			...prev,
			status_code: value
		}));
	};

	useEffect(() => {
		let filters = { ...searchValues };
		let parsedFilters = {};

		Object.keys(filters).forEach(key => {
			const value = filters[key];
			if (value !== null) {
				if (typeof value === 'string' && value.length === 0) return;
				if (key === 'status_code') {
					parsedFilters[key] = getStatusCode(value, t);
				} else {
					parsedFilters[key] = value;
				}
			}
		});

		if (Object.keys(parsedFilters).length > 0) {
			replaceSearch(history, 'my_reservations', parsedFilters);
		}
	}, [searchValues, history, t]);

	useEffect(() => {
		const params = parseMyReservationsParams(history.location.search);
		getMyReservations(params);
	}, [getMyReservations, history.location.search]);

	return (
		<SearchBarContainer>
			<Col xs={3}>
				<StyledInput
					name="file"
					type="text"
					placeholder=""
					onChange={e => handleInputChange('id', e.target.value.toLowerCase())}
					value={searchValues.id}
					label={t('general.FileNumber')}
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label={t('general.CreationDate')}
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={
						searchValues.emission_date
							? moment(searchValues.emission_date)
							: undefined
					}
					onChange={(date, dateString) =>
						handleInputChange(
							'emission_date',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label={t('general.ReservationFrom')}
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={searchValues.from ? moment(searchValues.from) : undefined}
					onChange={(date, dateString) =>
						handleInputChange(
							'from',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledDatePicker
					label={t('general.ReservationTo')}
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={searchValues.to ? moment(searchValues.to) : undefined}
					onChange={(date, dateString) =>
						handleInputChange(
							'to',
							date ? moment(date).format('YYYY-MM-DD') : undefined
						)
					}
				/>
			</Col>
			<Col xs={3}>
				<StyledInput
					name="pax_name"
					type="text"
					placeholder=""
					label={t('general.Passenger')}
					labelColor="#030F33"
					weight={400}
					fontSize="16px"
					value={searchValues.pax_name}
					onChange={e => handleInputChange('pax_name', e.target.value.toLowerCase())}
				/>
			</Col>
			<Col xs={3}>
				<StyledSearchSelect>
					<SearchSelect
						name="status_code"
						label={t('general.Status')}
						options={statusOptions} // Opciones traducidas.
						value={searchValues.status_code}
						onChange={handleStatusChange}
						allowClear
						className="styled-status-select"
					/>
				</StyledSearchSelect>
			</Col>
		</SearchBarContainer>
	);
};

const mapDispatchToProps = dispatch => ({
	getMyReservations: payload => getMyReservations(dispatch, payload)
});

export default connect(null, mapDispatchToProps)(SearchBar);
