import React from 'react';
import destinationIcon from '../assets/icons/city.svg';
import CancelReservation from '../modules/my_reservations/views/index/ReservationsTable/cancelReservation';

export const destinations = [
	{
		value: 'IGR',
		label: 'Puerto Iguazú'
	},
	{
		value: 'IGU',
		label: 'Foz do Iguaçu'
	},
	{
		value: 'ASU',
		label: 'Asunción'
	},
	{
		value: 'posadas',
		label: 'Posadas'
	},
	{
		value: 'cidad_del_este',
		label: 'Ciudad del Este'
	}
];

export const activitiesDestinations = [
	{
		value: 'ASU',
		label: 'Asunción',
		icon: destinationIcon
	},
	{
		value: 'IGU',
		label: 'Foz do Iguaçu',
		icon: destinationIcon
	},
	{
		value: 'IGR',
		label: 'Puerto Iguazú',
		icon: destinationIcon
	}
];

export const hotelsOptions = [
	{ value: 5, label: 'Suíça Hotel & Resort' },
	{ value: 6, label: 'Bourbon Foz do Iguaçu Hotel' },
	{ value: 7, label: 'Wyndham Golden Foz' },
	{ value: 8, label: 'Falls Galli Hotel' },
	{ value: 9, label: 'Grand Crucero Iguazú Hotel' }
];

export const pickUpLocationFoz = [
	{ value: 1, label: 'A1 Hotel' },
	{ value: 2, label: 'Aguas Do Iguaçu Hotel Centro' },
	{ value: 3, label: 'Ambassador Palace Hotel' },
	{ value: 4, label: 'Atalaia Adventure Cataratas Hostel' },
	{ value: 5, label: 'Bella Italia Hotel e Eventos' },
	{ value: 6, label: 'Bogari Hotel' },
	{ value: 7, label: 'Bourbon Cataratas do Iguaçu Resort' },
	{ value: 8, label: 'Bourbon Foz Do Iguacu Hotel (Business)' },
	{ value: 9, label: 'Canzi Cataratas Hotel' },
	{ value: 10, label: 'Carimã Hotel & Convention' },
	{ value: 11, label: 'Cataratas Park Hotel' },
	{ value: 12, label: 'CLH Suites Foz do Iguaçu' },
	{ value: 13, label: 'Colibri Iguassu Hostel' },
	{ value: 14, label: 'Concept Design Hostel & Suite' },
	{ value: 15, label: 'Continental Inn' },
	{ value: 16, label: 'Del Rey Quality Hotel' },
	{ value: 17, label: 'Dom Pedro I Palace Hotel' },
	{ value: 18, label: 'Falls Galli Hotel' },
	{ value: 19, label: 'Falls Plaza Hotel' },
	{ value: 20, label: 'Foz Express Hotel' },
	{ value: 21, label: 'Foz Family Hotel' },
	{ value: 22, label: 'Foz Plaza Hotel' },
	{ value: 23, label: 'Foz Presidente Comfort Hotel' },
	{ value: 24, label: 'Foz Presidente Hotel' },
	{ value: 25, label: 'Holiday Foz' },
	{ value: 26, label: 'Hostel Bambu' },
	{ value: 27, label: 'Hostel Bookafe UpFront' },
	{ value: 28, label: 'Hostel Estoril Foz' },
	{ value: 29, label: 'Hostel House' },
	{ value: 30, label: 'Hostel Mandala' },
	{ value: 31, label: 'Hostel Paudimar Falls Centro' },
	{ value: 32, label: 'Hotel 15 de Julho' },
	{ value: 33, label: 'Hotel Amaranta' },
	{ value: 34, label: 'Hotel Baviera' },
	{ value: 35, label: 'Hotel Blue Star II' },
	{ value: 36, label: 'Hotel Cassino Foz do Iguaçu' },
	{ value: 37, label: 'Hotel Cataratas' },
	{ value: 38, label: 'Hotel Colonial Iguaçu' },
	{ value: 39, label: 'Hotel Damen' },
	{ value: 40, label: 'Hotel Dan Inn Foz do Iguaçu' },
	{ value: 41, label: 'Hotel Dany' },
	{ value: 42, label: 'Hotel Foz do Iguaçu' },
	{ value: 43, label: 'Hotel Golden Park Internacional' },
	{ value: 44, label: 'Hotel HS' },
	{ value: 45, label: 'Hotel Ibis Budget Foz do Iguaçu' },
	{ value: 46, label: 'Hotel Ibis Foz do Iguaçu' },
	{ value: 47, label: 'Hotel Iguassu Inn' },
	{ value: 48, label: 'Hotel Lanville' },
	{ value: 49, label: 'Hotel Lawrence' },
	{ value: 50, label: 'Hotel Monalisa' },
	{ value: 51, label: 'Hotel San Remo' },
	{ value: 52, label: 'Hotel Seibt' },
	{ value: 53, label: 'Hotel Tarobá' },
	{ value: 54, label: 'Hotel Três Fronteiras' },
	{ value: 55, label: 'Hotel Trevita Foz' },
	{ value: 56, label: 'Hotel Village Foz' },
	{ value: 57, label: 'Hotel Vivere Cataratas' },
	{ value: 58, label: 'Iguaçu Plaza Hotel' },
	{ value: 59, label: 'Iguassu Charm Suites' },
	{ value: 60, label: 'Iguassu Express Hotel' },
	{ value: 61, label: 'Iguassu Flats' },
	{ value: 62, label: 'Iguassu Holiday Hotel' },
	{ value: 63, label: 'Imperial Hotel' },
	{ value: 64, label: 'Itaipu Hotel' },
	{ value: 65, label: 'Katharina House Hostel' },
	{ value: 66, label: 'La Maison Brasiliana' },
	{ value: 67, label: 'Líder Palace' },
	{ value: 68, label: 'Luz Hotel' },
	{ value: 69, label: 'Mabu Thermas Grand Resort' },
	{ value: 70, label: 'Manacá Hotel' },
	{ value: 71, label: 'Maria Ricca Hotel' },
	{ value: 72, label: 'Melizas Garden' },
	{ value: 73, label: 'Mirante Hotel' },
	{ value: 74, label: 'Moura Palace Hotel' },
	{ value: 75, label: 'Nacional Inn Foz do Iguaçu' },
	{ value: 76, label: 'Nadai Confort Hotel & Spa' },
	{ value: 77, label: 'Paudimar Falls Hostel' },
	{ value: 78, label: 'Pietro Angelo Hotel' },
	{ value: 79, label: 'Pop Hotel' },
	{ value: 80, label: 'Pousada Cataratas' },
	{ value: 81, label: 'Pousada Central Foz' },
	{ value: 82, label: 'Pousada Colibri' },
	{ value: 83, label: 'Pousada Evelina' },
	{ value: 84, label: 'Pousada Foz Igu' },
	{ value: 85, label: 'Pousada Golden Cataratas' },
	{ value: 86, label: 'Pousada Guata Porã' },
	{ value: 87, label: 'Pousada Hollywood' },
	{ value: 88, label: 'Pousada Nanai' },
	{ value: 89, label: 'Pousada Natureza Foz' },
	{ value: 90, label: "Pousada Quedas D'Água" },
	{ value: 91, label: 'Pousada Sonho Meu' },
	{ value: 92, label: 'Pousada Sua Casa Foz' },
	{ value: 93, label: 'Pousada Villas Iguassu' },
	{ value: 94, label: 'Prisma Hotel' },
	{ value: 95, label: 'Rafain Centro Hotel' },
	{ value: 96, label: 'Rafain Palace Hotel & Convention' },
	{ value: 97, label: 'Recanto Cataratas, Thermas, Resort e Convention' },
	{ value: 98, label: 'Rhema Iguassu Hotel' },
	{ value: 99, label: 'Royal Iguassu Hotel' },
	{ value: 100, label: 'Samambaia hotel' },
	{ value: 101, label: 'San Juan Eco Hotel' },
	{ value: 102, label: 'San Juan Tour' },
	{ value: 103, label: 'San Martin Cataratas Resort & Spa' },
	{ value: 104, label: 'San Rafael' },
	{ value: 105, label: 'Savaris Apart Hotel' },
	{ value: 106, label: 'Stella Solaris Cataratas' },
	{ value: 107, label: 'Suiça Hotel & Resort' },
	{ value: 108, label: 'Tetris Container Hostel' },
	{ value: 109, label: 'Viale Cataratas Hotel & Eventos' },
	{ value: 110, label: 'Viale Tower Hotel' },
	{ value: 111, label: 'Villa Canoas Hotel' },
	{ value: 112, label: 'Villa Iguaçu Palace Hotel' },
	{ value: 113, label: 'Vivaldi Cataratas' },
	{ value: 114, label: 'Vivaz Cataratas Hotel Resort' },
	{ value: 115, label: 'Wyndham Golden Foz Suites' }
];

export const pickUpLocationPuerto = [
	{ value: 0, label: 'Amérian Portal del Iguazú' },
	{ value: 1, label: 'Akwati Suites Iguazú' },
	{ value: 3, label: 'Ambay Suites' },
	{ value: 4, label: 'Apart Hotel El Paraiso' },
	{ value: 5, label: 'Apart Laboulaye' },
	{ value: 6, label: 'Arami Hotel & Lodge' },
	{ value: 7, label: 'Bosetti Apart Hotel' },
	{ value: 8, label: 'Boutique Hotel De La Fonte' },
	{ value: 9, label: 'Butterfly Hostel' },
	{ value: 10, label: 'Cabañas Merale' },
	{ value: 11, label: 'Cabañas Paseo del Yacaratiá' },
	{ value: 12, label: 'Cataratas Parque Hotel' },
	{ value: 13, label: 'Cataratas Parque Hotel' },
	{ value: 14, label: 'Complejo Turístico Americano' },
	{ value: 15, label: 'Costa Iguazú Apart Hotel' },
	{ value: 16, label: 'Edificio San Fernando' },
	{ value: 17, label: 'El Pueblito Iguazú' },
	{ value: 18, label: 'El Remanso Apart' },
	{ value: 19, label: 'El Rinconcito' },
	{ value: 20, label: 'Exe Hotel Cataratas' },
	{ value: 21, label: 'Falls Iguazu Hotel y Spa' },
	{ value: 22, label: 'Garden Stone' },
	{ value: 23, label: 'Gran Hotel Tourbillon & Lodge' },
	{ value: 24, label: 'Gran Meliá Iguazú' },
	{ value: 25, label: 'Hospedaje El Duende' },
	{ value: 26, label: 'Hospedaje Los Pinos' },
	{ value: 27, label: 'Hospedaje Los Vencejos' },
	{ value: 28, label: 'Hostel El Güembé Suites' },
	{ value: 29, label: 'Hostel Krieger Haus' },
	{ value: 30, label: 'Hostel Park Iguazú' },
	{ value: 31, label: 'Hosteria Casa Blanca Iguazú' },
	{ value: 32, label: 'Hosteria La Cabaña' },
	{ value: 33, label: 'Hostería Los Helechos' },
	{ value: 34, label: 'Hotel Amayal' },
	{ value: 35, label: 'Hotel Carmen' },
	{ value: 36, label: 'Hotel Don Horacio' },
	{ value: 37, label: 'Hotel Don Horacio' },
	{ value: 38, label: 'Hotel El Libertador' },
	{ value: 39, label: 'Hotel Iguazú Natural' },
	{ value: 40, label: 'Hotel Jardin de Iguazu' },
	{ value: 41, label: 'Hotel Lilian' },
	{ value: 42, label: 'Hotel Ox' },
	{ value: 43, label: 'Hotel Saint George' },
	{ value: 44, label: 'Hotel Sol Cataratas' },
	{ value: 45, label: 'Hotel Sol Cataratas' },
	{ value: 46, label: 'Hotel Sol Cataratas' },
	{ value: 47, label: 'Hotel Tropical' },
	{ value: 48, label: 'Iguazu Grand Resort Spa & Casino' },
	{ value: 49, label: 'Iguazu Grand Resort Spa & Casino' },
	{ value: 50, label: 'Iguazu Jungle Lodge' },
	{ value: 51, label: 'Iguazu Rey Hostel' },
	{ value: 52, label: 'Iguazu Royal' },
	{ value: 53, label: 'Iguazú Urban Hotel Express' },
	{ value: 54, label: 'Jasy Hotel' },
	{ value: 55, label: 'Kelta Hotel Puerto Iguazu' },
	{ value: 56, label: 'La Aldea de la Selva Lodge' },
	{ value: 57, label: 'La Cantera Lodge de Selva by DON' },
	{ value: 58, label: 'La Esquina del Bambu' },
	{ value: 59, label: 'La Familia Hotel' },
	{ value: 60, label: 'La Gran Manzana' },
	{ value: 61, label: 'La Strada Residencial' },
	{ value: 62, label: 'Latino Hotel' },
	{ value: 63, label: 'Loi Suites Iguazu' },
	{ value: 64, label: 'Los Helechos Hostal' },
	{ value: 65, label: 'Luces de la Selva' },
	{ value: 66, label: 'Luces de la Selva' },
	{ value: 67, label: 'Managua Apart.' },
	{ value: 68, label: 'Marangatu' },
	{ value: 69, label: 'Marcopolo Suites Iguazú' },
	{ value: 70, label: 'Mercure Iguazu Hotel Iru' },
	{ value: 71, label: 'Naipí Apart' },
	{ value: 72, label: 'NUEVO HOTEL MISIONES' },
	{ value: 73, label: 'O2 HOTEL IGUAZU' },
	{ value: 74, label: 'Orquideas Palace Hotel y Cabañas' },
	{ value: 75, label: 'Oxum Hotel' },
	{ value: 76, label: 'Palo Rosa Hotel y Cabañas' },
	{ value: 77, label: 'Palo Rosa Hotel y Cabañas' },
	{ value: 78, label: 'Passaros Suite Hotel' },
	{ value: 79, label: 'Petit Hotel Caraguata' },
	{ value: 80, label: 'Petit Hotel Panambi' },
	{ value: 81, label: 'Petit Hotel Si Mi Capitan' },
	{ value: 82, label: 'Pirayu Lodge Resort' },
	{ value: 83, label: 'Posada Colibrí' },
	{ value: 84, label: 'Posada del Jacarandá' },
	{ value: 85, label: 'Posada La Sorgente' },
	{ value: 86, label: 'Posada Los Tajibos' },
	{ value: 87, label: 'Raices Amambai Lodges' },
	{ value: 88, label: 'Raices Esturion' },
	{ value: 89, label: 'Rainforest Hotel Selva' },
	{ value: 90, label: 'Residencial Amigos' },
	{ value: 91, label: 'Residencial Iguazú Villa 14' },
	{ value: 92, label: 'Residencial Los Lapachos' },
	{ value: 93, label: 'Residencial Noelia Hostel' },
	{ value: 94, label: 'Residencial Nora' },
	{ value: 95, label: 'Residencial Uno Hostel' },
	{ value: 96, label: 'Secret garden iguazu' },
	{ value: 97, label: 'Selva de Laurel' },
	{ value: 98, label: 'Selvaje Lodge Iguazu' },
	{ value: 99, label: 'Tangoinn Beer Hotel' },
	{ value: 100, label: 'Tangoinn Downtown & Bar' },
	{ value: 101, label: 'Terra Iguazú Apart Hotel' },
	{ value: 102, label: 'Tierra Guaraní Lodge' },
	{ value: 103, label: 'Tucán Hostel' },
	{ value: 104, label: 'Tupã Hotel Boutique' },
	{ value: 105, label: 'Village Cataratas' },
	{ value: 106, label: 'Village del Sol Hotel' },
	{ value: 107, label: 'Yaguareté Lodge' },
	{ value: 108, label: 'Yvera Cataratas' },
	{ value: 109, label: 'Yvy Hotel de Selva' }
];

export const maxAdults = 10;
export const defaultAdults = 2;
export const maxRooms = 3;
export const maxChildren = 10;
export const optionAll = 'all';

export const reservationsColumns = t => [
	{
		title: t('myReservations.columns.file_id'),
		dataIndex: 'id',
		key: 'id',
		sorter: (a, b) => a.id - b.id
	},
	{
		title: t('myReservations.columns.created_at'),
		dataIndex: 'emission_date',
		key: 'emission_date',
		sorter: (a, b) => a.emission_date.localeCompare(b.emission_date)
	},
	{
		title: t('myReservations.columns.passenger'),
		dataIndex: 'pax_name',
		key: 'pax_name',
		sorter: (a, b) => a.pax_name.localeCompare(b.pax_name)
	},
	{
		title: t('myReservations.columns.from'),
		dataIndex: 'from',
		key: 'from',
		sorter: (a, b) => a.from.localeCompare(b.from)
	},
	{
		title: t('myReservations.columns.to'),
		dataIndex: 'to',
		key: 'to',
		sorter: (a, b) => a.to.localeCompare(b.to)
	},
	{
		title: t('myReservations.columns.status'),
		dataIndex: 'status',
		key: 'status',
		sorter: (a, b) => a.status.localeCompare(b.status)
	},
	{
		title: t('myReservations.columns.amount'),
		dataIndex: 'amount',
		key: 'amount',
		sorter: (a, b) => a.amount - b.amount
	},
	{
		title: '',
		key: 'cancel',
		dataIndex: 'cancel',
		render: (text, record) => (
			<CancelReservation status={record.status_code} id={record.id} />
		)
	}
];

export const countriesOptions = [
	{
		value: 'DE',
		label: 'ALEMANIA'
	},
	{
		value: 'AI',
		label: 'ANGUILA'
	},
	{
		value: 'AG',
		label: 'ANTIGUA Y BARBUDA'
	},
	{
		value: 'AN',
		label: 'ANTILLAS HOLANDESAS'
	},
	{
		value: 'AR',
		label: 'ARGENTINA'
	},
	{
		value: 'AW',
		label: 'ARUBA'
	},
	{
		value: 'AS',
		label: 'ASSUNÇAO'
	},
	{
		value: 'AU',
		label: 'AUSTRALIA'
	},
	{
		value: 'AT',
		label: 'AUSTRIA'
	},
	{
		value: 'BS',
		label: 'BAHAMAS'
	},
	{
		value: 'BB',
		label: 'BARBADOS'
	},
	{
		value: 'BE',
		label: 'BELGICA'
	},
	{
		value: 'BO',
		label: 'BOLIVIA'
	},
	{
		value: 'BR',
		label: 'BRASIL'
	},
	{
		value: 'CA',
		label: 'CANADA'
	},
	{
		value: 'KY',
		label: 'CAYMAN ISLAS'
	},
	{
		value: 'CL',
		label: 'CHILE'
	},
	{
		value: 'CN',
		label: 'CHINA'
	},
	{
		value: 'CO',
		label: 'COLOMBIA'
	},
	{
		value: 'CR',
		label: 'COSTA RICA'
	},
	{
		value: 'CC',
		label: 'CROACIA'
	},
	{
		value: 'CU',
		label: 'CUBA'
	},
	{
		value: 'DK',
		label: 'DINAMARCA'
	},
	{
		value: 'EC',
		label: 'ECUADOR'
	},
	{
		value: 'EG',
		label: 'EGIPTO'
	},
	{
		value: 'EI',
		label: 'ESCOCIA'
	},
	{
		value: 'ES',
		label: 'ESPAÑA'
	},
	{
		value: 'ET',
		label: 'ETIOPIA'
	},
	{
		value: 'FJ',
		label: 'FIDJI ISLAND'
	},
	{
		value: 'PH',
		label: 'FILIPINAS'
	},
	{
		value: 'FK',
		label: 'FINLANDIA'
	},
	{
		value: 'FR',
		label: 'FRANCIA'
	},
	{
		value: 'GB',
		label: 'GRAN BRETAÑA'
	},
	{
		value: 'GR',
		label: 'GRECIA'
	},
	{
		value: 'GD',
		label: 'GRENADA'
	},
	{
		value: 'GP',
		label: 'GUADALOUPE'
	},
	{
		value: 'GU',
		label: 'GUATEMALA'
	},
	{
		value: 'NL',
		label: 'HOLANDA'
	},
	{
		value: 'HN',
		label: 'HUNGRIA'
	},
	{
		value: 'IE',
		label: 'IRLANDA'
	},
	{
		value: 'VI',
		label: 'ISLAS VIRGENES AMERICANAS'
	},
	{
		value: 'VG',
		label: 'ISLAS VIRGENES BRITANICAS'
	},
	{
		value: 'IL',
		label: 'ISRAEL'
	},
	{
		value: 'IT',
		label: 'ITALIA'
	},
	{
		value: 'JM',
		label: 'JAMAICA'
	},
	{
		value: 'JP',
		label: 'JAPON'
	},
	{
		value: 'JO',
		label: 'JORDANIA'
	},
	{
		value: 'IN',
		label: 'LA INDIA'
	},
	{
		value: 'MY',
		label: 'MALASIA'
	},
	{
		value: 'MA',
		label: 'MARRUECOS'
	},
	{
		value: 'MR',
		label: 'MAURITIUS'
	},
	{
		value: 'MX',
		label: 'MEXICO'
	},
	{
		value: 'NO',
		label: 'NORUEGA'
	},
	{
		value: 'NZ',
		label: 'NUEVA ZELANDA'
	},
	{
		value: 'PY',
		label: 'PANAMA'
	},
	{
		value: 'PA',
		label: 'PARAGUAY'
	},
	{
		value: 'PE',
		label: 'PERU'
	},
	{
		value: 'PF',
		label: 'POLINESIA FRANCESA'
	},
	{
		value: 'PL',
		label: 'POLONIA'
	},
	{
		value: 'PT',
		label: 'PORTUGAL'
	},
	{
		value: 'PR',
		label: 'PUERTO RICO'
	},
	{
		value: 'CZ',
		label: 'REP. CHECA'
	},
	{
		value: 'RC',
		label: 'REPUBLICA CHECA'
	},
	{
		value: 'DO',
		label: 'REPUBLICA DOMINICANA'
	},
	{
		value: 'RO',
		label: 'RUMANIA'
	},
	{
		value: 'RU',
		label: 'RUSIA'
	},
	{
		value: 'SY',
		label: 'SEYCHELLES'
	},
	{
		value: 'SC',
		label: 'SEYCHELLES'
	},
	{
		value: 'KN',
		label: 'ST. KITTS &amp; NEVIS'
	},
	{
		value: 'LC',
		label: 'ST. LUCIA'
	},
	{
		value: 'ZA',
		label: 'SUDAFRICA'
	},
	{
		value: 'SE',
		label: 'SUECIA'
	},
	{
		value: 'CH',
		label: 'SUIZA'
	},
	{
		value: 'TH',
		label: 'THAILANDIA'
	},
	{
		value: 'TZ',
		label: 'TUNEZ'
	},
	{
		value: 'TR',
		label: 'TURQUIA'
	},
	{
		value: 'US',
		label: 'U.S.A.'
	},
	{
		value: 'UG',
		label: 'UGANDA'
	},
	{
		value: 'UY',
		label: 'URUGUAY'
	},
	{
		value: 'VE',
		label: 'VENEZUELA'
	}
];

export const getCountryLabelByValue = value => {
	const country = countriesOptions.find(option => option.value === value);
	return country ? country.label : null;
};
