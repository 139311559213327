/*----- Core -----*/
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import RoomResults from './results';
import { PeopleNumberContainer, Img } from './styles';

/*----- Assets -----*/
import BedIcon from '../../../../assets/icons/bed.svg';

export const PassengerResults = ({ people, onClick }) => {
	const { t } = useTranslation();

	return (
		<RoomResults people={people} onClick={onClick} label={t('general.passengers')} />
	);
};

export const MultipleRoomResults = ({ people, onClick, roomsCount }) => {
	const { t } = useTranslation();

	return (
		<RoomResults people={people} onClick={onClick} label={t('general.rooms')}>
			<Img src={BedIcon} alt="Bed icon" />
			<PeopleNumberContainer>{roomsCount}</PeopleNumberContainer>
		</RoomResults>
	);
};

/*----- PropTypes -----*/
PassengerResults.propTypes = {
	people: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired
};

MultipleRoomResults.propTypes = {
	people: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
	roomsCount: PropTypes.number.isRequired
};
