import React from 'react';
import { StyledModal, TitleContainer, StyledTitle, StyleKind } from './styles';
import MobileModal from '../MobileModal';
import { isMobile } from '../../../utilities/utils';
import SeeDetailsInfo from './SeeDetailsInfo';
import { useTranslation } from 'react-i18next';

const SeeDetailsModal = ({ kind, title, details, visible, onCancel }) => {
	const { t, i18n } = useTranslation();

	const getFormattedDetailsForLanguage = (details, language) => {
		const delimiters = {
			es: 'ESPAÑOL:',
			'pt-BR': 'PORTUGUÉS:',
			en: 'INGLÉS:'
		};

		const selectedDelimiter = delimiters[language];
		const startIndex = details.findIndex(detail =>
			detail.includes(selectedDelimiter)
		);

		if (startIndex === -1) {
			return [];
		}

		const nextLanguages = Object.values(delimiters).filter(
			d => d !== selectedDelimiter
		);
		const endIndex = details.findIndex(
			(detail, idx) =>
				idx > startIndex &&
				nextLanguages.some(delimiter => detail.includes(delimiter))
		);

		const filteredDetails = details.slice(
			startIndex,
			endIndex === -1 ? details.length : endIndex
		);

		if (filteredDetails[0].includes(selectedDelimiter)) {
			filteredDetails[0] = filteredDetails[0].replace(selectedDelimiter, '').trim();
		}

		return filteredDetails;
	};

	const filteredDetails = getFormattedDetailsForLanguage(details, i18n.language);

	// Título del modal
	const Title = () => (
		<TitleContainer>
			<StyleKind content={kind} />
			<StyledTitle content={title} />
		</TitleContainer>
	);

	// Modal para web
	const ModalWeb = () => (
		<StyledModal
			visible={visible}
			onCancel={onCancel}
			closable={false}
			title={<Title />}
			footer={null}
			width="700px"
		>
			<SeeDetailsInfo details={filteredDetails} />
		</StyledModal>
	);

	// Modal para móvil
	const ModalMobile = () => (
		<MobileModal
			visible={visible}
			onCancel={onCancel}
			title={t('general.details')}
			justifyStart
			content={
				<>
					<Title />
					<SeeDetailsInfo details={filteredDetails} />
				</>
			}
		/>
	);

	// Devolver el modal correspondiente según el dispositivo
	return isMobile() ? <ModalMobile /> : <ModalWeb />;
};

export default SeeDetailsModal;
