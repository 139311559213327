import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

/*----- Components -----*/
import TransferSaleCard from '../../../../components/card/TransferSaleCard';

/*----- Selectors -----*/
import { transfersData } from '../../selectors/transfersSelector';

/*----- Assets -----*/
import suitcase from '../../../../assets/icons/travel-suitcase.svg';
import person from '../../../../assets/icons/person-icon.svg';

const ResultList = ({ transfersData }) => {
	const { t, i18n } = useTranslation();

	const iconServices = [
		{ icon: suitcase, label: t('general.suitCase') },
		{ icon: person, label: t('general.person') }
	];

	return transfersData.map(data => {
		let info = data.info.observations[1].text;
		let details = [];
		let description = '';

		if (info) {
			details = info.split('\n\n');
			const languageSections = {
				es: 'Incluidos:',
				'pt-BR': 'Incluído:',
				en: 'Included:'
			};

			const currentLang = i18n.language;
			const langPrefix = languageSections[currentLang] || 'Included:';
			const matchedSection = details.find(detail => detail.startsWith(langPrefix));

			if (matchedSection) {
				description = matchedSection
					.replace(new RegExp(`^${langPrefix}\\s*-\\s*`), '')
					.replace(/\n- Guía.*| Guia.*| Guides available.*$/, '')
					.trim();
			}
		}

		return data.rates.map((rate, i) => (
			<TransferSaleCard
				key={i}
				title={t(data.name)}
				validitiesFrom={data.info.validities[0].from}
				validitiesTo={data.info.validities[0].to}
				destination={`/transfers/${rate.item_id}/checkout`}
				price={
					rate.amount_details.occupancy.passenger.sale +
					rate.amount_details.occupancy.passenger.iva
				}
				currency={rate.currency}
				iconServices={iconServices}
				itemId={rate.itemId}
				action={t('general.reserve')}
				kind={data.info.kind}
				category={data.info.category.name}
				description={description}
				details={details}
			/>
		));
	});
};

const mapStateToProps = state => {
	return {
		transfersData: transfersData(state)
	};
};

export default connect(mapStateToProps, null)(ResultList);
