import Regular from '../../../../assets/images/tranfers/transferRegular.jpeg';
import Premium from '../../../../assets/images/tranfers/transferPremium.jpeg';
import Privado from '../../../../assets/images/tranfers/transferPrivado.jpeg';

export const transferData = [
	{
		name: 'transfers.data.regular.name',
		image: Regular,
		description: 'transfers.data.regular.description',
		small_description: 'transfers.data.regular.small_description',
		label: ''
	},
	{
		name: 'transfers.data.private.name',
		image: Privado,
		description: 'transfers.data.private.description',
		small_description: 'transfers.data.private.small_description',
		label: 'transfers.data.private.label'
	},
	{
		name: 'transfers.data.premium.name',
		image: Premium,
		description: 'transfers.data.premium.description',
		small_description: 'transfers.data.premium.small_description',
		label: 'transfers.data.premium.label'
	}
];
