/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Components -----*/
import Title from '../text/Title';
import DefaultButton from '../button/DefaultButton';
import { Row, Col } from 'antd';
import Stars from '../../components/commons/Stars';
import { useTranslation } from 'react-i18next';

/*----- Commons -----*/
import Device from '../../commons/Device';

/*----- Styles-----*/
import CardCommonStyles from '../../commons/styles/Card';

const SM_HEIGHT = 'min-height: 340px; height: 100%';
const MD_HEIGHT = 'min-height: 210px; height: 100%';

const StyledHotelCard = styled.article`
	${CardCommonStyles}
	position: relative;

	.content-button {
		position: absolute;
		bottom: 0;
	}

	.default-button {
		margin: 0 auto;
		min-width: 160px;
	}
	.product-card__title-container {
		.custom-title {
			min-height: 85px;
		}
	}

	.product-card-label {
		padding-top: 3px;
		padding-bottom: 6px;

		img:last-child {
			margin-right: 0px !important;
		}
	}

	@media ${Device.sm} {
		${SM_HEIGHT}
	}

	@media ${Device.md} {
		${MD_HEIGHT}
	}

	@media ${Device.xs} {
		.content-button {
			position: static;
		}
		.product-card__title-container {
			.custom-title {
				min-height: initial;
				margin-bottom: 0px;
			}
		}
	}
`;

/*----- Export -----*/
const HotelCard = ({ title, imageUrl, stars, destination, onClick }) => {
	const { t } = useTranslation();
	return (
		<StyledHotelCard>
			<Row type="flex">
				<Col
					span={24}
					className="product-card__image-container"
					style={{ backgroundImage: `url(${imageUrl})` }}
				>
					<div className="product-card-label">
						<Stars stars={stars} />
					</div>
				</Col>

				<Col span={24}>
					<div className="product-card__title-container">
						<Title content={title} size={3} />
					</div>
				</Col>

				<Col span={24} className="content-button">
					<DefaultButton
						content={t('general.consult')}
						destination={destination}
						onClick={onClick}
					/>
				</Col>
			</Row>
		</StyledHotelCard>
	);
};
export default HotelCard;

/*----- PropTypes -----*/
HotelCard.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	stars: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired
};
