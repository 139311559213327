import React from 'react';
import PropTypes from 'prop-types';
import DefaultSelect from '../../DefaultSelect';
import getOptions from './options';
import { useTranslation } from 'react-i18next';

const ChildrenAgeSelect = ({ index, onChange, className, value }) => {
	const { t } = useTranslation();

	return (
		<div key={index} className={className}>
			<DefaultSelect
				defaultValue={value}
				name={`${t('general.childAge')} ${index + 1}`}
				options={getOptions()}
				onChange={onChange}
			/>
		</div>
	);
};

export default ChildrenAgeSelect;

/*----- PropTypes -----*/
ChildrenAgeSelect.propTypes = {
	index: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	className: PropTypes.string
};
