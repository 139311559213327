/*----- Core -----*/
import React from 'react';
import { useTranslation } from 'react-i18next';

/*----- Assets -----*/
import MinusIcon from '../../../../assets/icons/minus.svg';
import PlusIcon from '../../../../assets/icons/plus.svg';

/*----- Components -----*/
import PeopleContainer from '../PeopleContainer';

/*----- Styles -----*/
import { TitleContainer, RoomListContainer, StyledTitle } from '../styles';
import { StyledChildrenAgeSelect, StyledButton, StyledValue } from './styles';

/*----- Constants -----*/
import { maxAdults, maxChildren } from '../../../../utilities/constants';

const PassengerRoom = ({ moreRooms, onChange, value, room }) => {
	const { t } = useTranslation();

	const removeAdult = event => {
		event.preventDefault();

		if (value.adults_number === 1) return;

		const updatedAdults = value.adults_number - 1;

		onChange({
			...value,
			adults_number: updatedAdults
		});
	};

	const addAdult = event => {
		event.preventDefault();

		if (value.adults_number === maxAdults) return;

		const updatedAdults = value.adults_number + 1;

		onChange({
			...value,
			adults_number: updatedAdults
		});
	};

	const addMinor = event => {
		event.preventDefault();

		if (value.children_ages.length === maxChildren) return;

		let updatedChildren = value.children_ages.slice();
		updatedChildren.push(1);

		onChange({
			...value,
			children_ages: updatedChildren
		});
	};

	const removeMinor = event => {
		event.preventDefault();

		let updatedChildren = value.children_ages.slice();
		updatedChildren.pop();

		onChange({
			...value,
			children_ages: updatedChildren
		});
	};

	const changeChildrenAge = (updatedValue, index) => {
		let updatedChildren = value.children_ages;
		updatedChildren[index] = updatedValue;

		onChange({
			...value,
			children_ages: updatedChildren
		});
	};

	return (
		<RoomListContainer>
			<TitleContainer>
				<StyledTitle
					content={
						moreRooms
							? `${t('checkout.guests.self')} ${t('hotels.room')} ${room + 1}`
							: t('general.passengers')
					}
					size={5}
				/>
			</TitleContainer>

			<div>
				<PeopleContainer title={t('general.adults')}>
					<StyledButton icon={MinusIcon} onClick={removeAdult} />

					<StyledValue>{value.adults_number}</StyledValue>

					<StyledButton icon={PlusIcon} onClick={addAdult} />
				</PeopleContainer>
			</div>

			<div>
				<PeopleContainer title={t('general.minors')}>
					<StyledButton icon={MinusIcon} onClick={removeMinor} />

					<StyledValue>{value.children_ages.length}</StyledValue>

					<StyledButton icon={PlusIcon} onClick={addMinor} />
				</PeopleContainer>
			</div>

			{value.children_ages.map((_age, index) => (
				<StyledChildrenAgeSelect
					index={index}
					key={index}
					onChange={value => changeChildrenAge(value, index)}
					value={_age}
				/>
			))}
		</RoomListContainer>
	);
};

export default PassengerRoom;
