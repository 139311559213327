/*----- Core -----*/
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

/*----- Components -----*/
import Title from '../text/Title';
import DefaultButton from '../button/DefaultButton';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import CardCommonStyles from '../../commons/styles/Card';
import Device from '../../commons/Device';

const SM_HEIGHT = 'min-height: 340px; height: 100%';
const MD_HEIGHT = 'min-height: 210px; height: 100%';

const StyledTransferCard = styled.div`
	${CardCommonStyles}

	position: relative;

	.content-button {
		position: relative;
		bottom: 0;
		margin-bottom: 12px;
	}

	.default-button {
		margin: 0 auto;
		min-width: 160px;
	}

	.card__description p {
		padding-top: 0px;
		padding-bottom: 0px;
		margin-bottom: 6px;
	}

	@media ${Device.sm} {
		${SM_HEIGHT}
	}

	@media ${Device.md} {
		${MD_HEIGHT}
	}

	@media ${Device.xs} {
		.content-button {
			position: static;
		}

		.card__description p {
			padding-bottom: 0px;
			margin-bottom: 5px;
		}
	}
`;

/*----- Export -----*/
const TransferCard = ({
	title,
	imageUrl,
	description,
	destination,
	label,
	onClick
}) => {
	const { t } = useTranslation();

	return (
		<StyledTransferCard>
			<Row type="flex">
				<Col
					span={24}
					className="product-card__image-container"
					style={{ backgroundImage: `url(${imageUrl})` }}
				>
					{label ? <p className="product-card-label">{label}</p> : ''}
				</Col>

				<Col span={24}>
					<div className="product-card__title-container">
						<Title content={title} size={3} />
					</div>
				</Col>

				<Col span={24} className="card__description">
					<p>{description}</p>
				</Col>

				<Col span={24} className="content-button">
					<DefaultButton
						content={t('general.consult')}
						destination={destination}
						onClick={onClick}
					/>
				</Col>
			</Row>
		</StyledTransferCard>
	);
};

export default TransferCard;

/*----- PropTypes -----*/
TransferCard.propTypes = {
	title: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	destination: PropTypes.string.isRequired,
	label: PropTypes.string,
	onClick: PropTypes.func.isRequired
};
