import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserCurrency, isMobile } from '../../../../utilities/utils';

/*----- Components -----*/
import {
	StyledText,
	StyledTitle,
	DescriptionContainer,
	StyledIconArrow,
	ArrowDiv,
	StyledArrowText
} from './styles';

const ActivityDescription = ({
	title = 'general.knowMore',
	description,
	fromPackage = false
}) => {
	const { t, i18n } = useTranslation();
	const [showShortDescription, setShowShortDescription] = useState(isMobile());

	const slug = {
		ARS: t('general.day', { lng: i18n.language }),
		R$: t('general.day', { lng: i18n.language }),
		USD: t('general.day', { lng: i18n.language })
	};

	const parseDescriptionByLanguage = text => {
		const sections = { es: '', 'pt-BR': '', en: '' };
		const regex = /(ESPAÑOL|PORTUGUÉS|INGLÉS)/g;
		const parts = text.split(regex);

		for (let i = 0; i < parts.length; i++) {
			if (parts[i] === 'ESPAÑOL') sections.es = parts[i + 1]?.trim() || '';
			if (parts[i] === 'PORTUGUÉS') sections['pt-BR'] = parts[i + 1]?.trim() || '';
			if (parts[i] === 'INGLÉS') sections.en = parts[i + 1]?.trim() || '';
		}

		if (!sections.es && !sections['pt-BR'] && !sections.en) {
			sections.es = sections['pt-BR'] = sections.en = text;
		}

		return sections;
	};

	const sectionsByLanguage = parseDescriptionByLanguage(description);
	const languageContent = sectionsByLanguage[i18n.language] || description;

	const sectionTitles = [
		t('general.activity'),
		t('general.category'),
		t('general.duration'),
		t('general.availability'),
		t('general.descriptive'),
		t('general.included'),
		t('general.excluded'),
		t('general.observations'),
		t('general.policyMinors'),
		t('general.cancellationPolicy'),
		t('general.pickUp')
	];

	const orderedSections = {};
	sectionTitles.forEach(title => {
		const regex = new RegExp(
			`${title}:\\s*([^]+?)(?=(\\b${sectionTitles.join('\\b|\\b')}\\b|$))`
		);
		const match = languageContent.match(regex);
		if (match) {
			orderedSections[title] = match[1].trim();
		}
	});

	const packagedays = languageContent
		.split(/Día \d\)|Dia \d\)|Day \d\)/)
		.filter(Boolean);

	const additionalDetailsPattern = /Incluidos:|Included:|Incluídos:|Excluidos:|Excluded:|Excluídos:|Observaciones:|Observations:|Observações:|Política de Menores:|Policy for minors:|Política de Cancelación:|Cancellation Policy:|Horario de Recogida:|Departure Time:|Horário de Saída:/g;

	const additionalDetails = languageContent.match(additionalDetailsPattern);
	const lastDayContent = additionalDetails
		? packagedays[packagedays.length - 1]
		: null;
	const parsedDetails = {};

	if (lastDayContent && additionalDetails) {
		const sections = lastDayContent.split(
			/(Incluidos:|Included:|Incluídos:|Excluidos:|Excluídos:|Excluded:|Observaciones:|Observações:|Observations:|Política de Menores:|Política de Menores:|Policy for minors:|Política de Cancelación:|Política de Cancelamento:|Cancellation Policy:|Horario de Recogida:|Horário de Saída:|Departure Time:)/
		);
		for (let i = 1; i < sections.length; i += 2) {
			parsedDetails[sections[i].trim()] = sections[i + 1]?.trim();
		}
		packagedays[packagedays.length - 1] = sections[0];
	}

	if (fromPackage) {
		return (
			<DescriptionContainer>
				<StyledTitle content={t(title)} size={3} />
				{packagedays.map((day, index) => (
					<React.Fragment key={index}>
						<StyledTitle
							content={`${slug[getUserCurrency().name]} ${index + 1}`}
							size={4}
						/>
						<StyledText content={day.trim()} />
					</React.Fragment>
				))}
				{Object.keys(parsedDetails).length > 0 && (
					<>
						{Object.entries(parsedDetails).map(([section, content], index) => (
							<div key={index}>
								<StyledTitle content={section} size={4} />
								<StyledText content={content.trim()} />
							</div>
						))}
					</>
				)}
			</DescriptionContainer>
		);
	}

	return (
		<DescriptionContainer>
			<StyledTitle content={t(title)} size={3} />
			{Object.entries(orderedSections).map(([section, content], index) => (
				<div key={index}>
					<StyledTitle content={section} size={4} />
					<StyledText content={content} />
				</div>
			))}
			{isMobile() && (
				<ArrowDiv>
					<StyledArrowText bold content={t('general.seeMore')} />
					<StyledIconArrow
						type={showShortDescription ? 'down' : 'up'}
						onClick={() => setShowShortDescription(!showShortDescription)}
					/>
				</ArrowDiv>
			)}
		</DescriptionContainer>
	);
};

export default ActivityDescription;
