import React from 'react';

/*----- Assets -----*/
import StarIcon from '../assets/icons/star.svg';
import DualBedIcon from '../components/icons/RoomIcon';
import HotelIcon from '../components/icons/HotelIcon';
import UserIcon from '../components/icons/UserIcon';
import RoomIcon from '../components/icons/RoomIcon';
import PacksIcon from '../assets/icons/services/Suitcase';
import TransferIcon from '../assets/icons/services/Van';
import ActivityIcon from '../assets/icons/services/Map';
import PackageIcon from '../assets/icons/services/Suitcase';
import ConfirmationIcon from '../components/icons/ConfirmationIcon';
import TicketIcon from '../components/icons/TicketIcon';
import BusIcon from '../components/icons/BusIcon';
import ExcursionsIcon from '../assets/icons/services/Map';
import PersonIcon from '../components/icons/PersonIcon';
import DetailIcon from '../components/icons/DetailIcon';
import CartIcon from '../components/icons/CartIcon';
/*----- Moment -----*/
import moment from 'moment';
import 'moment/locale/es';
import { buildSearchUrl } from './buildSearchQuery';
import { getCurrentUser } from '../authentication/currentUser';
import { notification } from 'antd';
import { getCheckoutTransfer } from '../services/TransferCheckoutService';
import { getCheckoutActivity } from '../services/ActivityCheckoutService';
import { getCheckoutHotel } from '../services/HotelCheckoutService';
import { getCheckoutPackages } from '../services/PackagesCheckoutService';
import { getCheckoutCart } from '../services/CartCheckoutService';
import { getSearchQuery } from '../services/SearchService';

moment.locale('es');
const dateFormats = ['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'];
const dateFormatLocal = 'DD-MM-YYYY';
const dateFormtToServer = 'YYYY-MM-DD';

export const renderStars = (
	stars,
	style = { width: '16px', marginRight: '8px' }
) => {
	let items = [];
	for (let i = 0; i < stars; i++) {
		items.push(<img src={StarIcon} key={i} alt="star icon" style={style} />);
	}
	return items;
};

export const getPrintableLocalDate = str_date => {
	return moment(str_date, dateFormtToServer).format(dateFormatLocal);
};

export const formatPrintableDate = str_date => {
	let date = moment(str_date, dateFormats);
	return date.format('ddd D-MM-YYYY');
};

export const getDateToServer = str_date => {
	return moment(str_date, dateFormats).format(dateFormtToServer);
};

export const getDiffDays = (dateFrom, dateTo) => {
	return dateTo.diff(dateFrom, 'days');
};

export const capitalize = value => {
	if (typeof value !== 'string') return '';
	return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isEmpty = value => {
	value = value || '';
	return !value.trim().length;
};

const getCounterPassenger = hotelRate => {
	let counter = 0;
	hotelRate.rooms.forEach(room => {
		counter += room.requested.adults_number + room.requested.children_ages.length;
	});
	return counter;
};

export const parseDetail = hotelRate => {
	const dual_bed = 'HABITACIÓN DOBLE';
	const single_bed = 'HABITACIÓN INDIVIDUAL';
	const triple_bed = 'HABITACIÓN TRIPLE';
	let itemIcon = [];

	if (!hotelRate || !hotelRate.rooms) {
		return itemIcon;
	}

	let count_dual_bed = 0;
	let count_single_bed = 0;
	let count_triple_bed = 0;

	hotelRate.rooms.forEach(room => {
		if (room.description.toUpperCase().includes(dual_bed)) {
			count_dual_bed++;
		} else if (room.description.toUpperCase().includes(single_bed)) {
			count_single_bed++;
		} else if (room.description.toUpperCase().includes(triple_bed)) {
			count_triple_bed++;
		}
	});
	if (count_triple_bed) {
		itemIcon.push({
			icon: <DualBedIcon />,
			description: `${count_triple_bed} Hab. Triple`
		});
	}
	if (count_dual_bed) {
		itemIcon.push({
			icon: <DualBedIcon />,
			description: `${count_dual_bed} Hab. doble`
		});
	}
	if (count_single_bed) {
		itemIcon.push({
			icon: <DualBedIcon />,
			description: `${count_single_bed} Hab. individual`
		});
	}

	const total_person = getCounterPassenger(hotelRate);
	const descrip_person = total_person > 1 ? 'Personas' : 'Persona';

	itemIcon.push({
		icon: <UserIcon />,
		description: `${total_person} ${descrip_person}`
	});

	return itemIcon;
};

export const hotelSteps = rate_id => {
	return [
		{
			title: 'hotels.hotel',
			destination: `/hotels/${buildSearchUrl()}`,
			icon: HotelIcon
		},
		{
			title: 'general.rooms',
			destination: `/hotels/${rate_id}`,
			icon: RoomIcon
		},
		{
			title: 'general.passengers',
			destination: `/hotels/${rate_id}/checkout`,
			icon: PersonIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/hotels/${rate_id}/checkout/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/hotels/${rate_id}/reservation`,
			icon: TicketIcon
		}
	];
};

export const transferSteps = rate_id => {
	return [
		{
			title: 'transfers.title',
			destination: `/transfers/${buildSearchUrl()}`,
			icon: BusIcon
		},
		{
			title: 'general.passengers',
			destination: `/transfers/${rate_id}/checkout`,
			icon: PersonIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/transfers/${rate_id}/checkout/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/transfers/${rate_id}/reservation`,
			icon: TicketIcon
		}
	];
};

export const activitiesSteps = rate_id => {
	return [
		{
			title: 'activities.title',
			destination: `/activities/${buildSearchUrl()}`,
			icon: ExcursionsIcon
		},
		{
			title: 'checkout.detail',
			destination: `/activities/${rate_id}`,
			icon: DetailIcon
		},
		{
			title: 'general.passengers',
			destination: `/activities/${rate_id}/checkout`,
			icon: PersonIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/activities/${rate_id}/checkout/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/activities/${rate_id}/reservation`,
			icon: TicketIcon
		}
	];
};

export const packagesSteps = packageId => {
	return [
		{
			title: 'packages.title',
			destination: `/packages/${buildSearchUrl()}`,
			icon: PacksIcon
		},
		{
			title: 'checkout.detail',
			destination: `/packages/${packageId}`,
			icon: ExcursionsIcon
		},
		{
			title: 'general.passengers',
			destination: `/packages/${packageId}/checkout`,
			icon: PersonIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/packages/${packageId}/checkout/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/packages/${packageId}/reservation`,
			icon: TicketIcon
		}
	];
};

export const packagesWithHotelSteps = (packageId, hotelId) => {
	const steps = [
		{
			title: 'Paquetes',
			destination: `/packages/${buildSearchUrl()}`,
			icon: PacksIcon
		}
	];

	return [
		...steps,
		{
			title: 'Hotel',
			destination: `/packages/${packageId}/hotels/search`,
			icon: HotelIcon
		} /* ,
		{
			title: 'Habitación',
			destination: `/packages/${packageId}/hotels/${hotelId}`,
			icon: RoomIcon
		}, */,
		{
			title: 'Pasajeros',
			destination: `/packages/${packageId}/checkout`,
			icon: PersonIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/packages/${packageId}/checkout/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/packages/${packageId}/reservation`,
			icon: TicketIcon
		}
	];
};

export const cartSteps = () => {
	return [
		{
			title: 'cart.checkout.title',
			destination: `/cart`,
			icon: CartIcon
		},
		{
			title: 'checkout.confirmation',
			destination: `/cart/confirm`,
			icon: ConfirmationIcon
		},
		{
			title: 'reservation.endOfReservation',
			destination: `/cart/reservation`,
			icon: TicketIcon
		}
	];
};

export const isMobile = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
};

export const currencies = {
	DOLAR1: {
		name: 'USD',
		min: 0,
		max: 800
	},
	REAL1: {
		name: 'R$',
		min: 0,
		max: 4000
	},
	PESOS1: {
		name: 'ARS',
		min: 0,
		max: 2000000
	},
	DOLAR: {
		name: 'USD',
		min: 0,
		max: 800
	},
	REAL: {
		name: 'R$',
		min: 0,
		max: 4000
	},
	PESOS: {
		name: 'ARS',
		min: 0,
		max: 2000000
	}
};

export const getUserCurrency = () => {
	const user = getCurrentUser();
	if (!user.preset || !currencies[user.preset]) {
		return currencies.DOLAR1;
	}
	return currencies[user.preset];
};

export const formatCurrency = (value, replaced = '.') => {
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, replaced);
};

export const getTotalPrice = item => {
	if (item.type === 'hotel') {
		return item.rate.amount_with_taxes;
	}
	return (
		item.rate.amount_details.passenger.sale + item.rate.amount_details.passenger.iva
	);
};

export const getTotalAmount = (items = []) =>
	items.length
		? items.reduce((totalAmount, item) => totalAmount + getTotalPrice(item), 0)
		: 0;

export const addToCartHandler = (
	newCartItem,
	service,
	addToCart,
	history,
	setCheckout,
	setNotification
) => {
	addToCart({ ...newCartItem, ...service });
	setNotification();
	setCheckout();
	history.push('/');
};

export const getIconByService = service => {
	switch (service) {
		case 'transfer':
			return <TransferIcon />;
		case 'excursion':
			return <ActivityIcon />;
		case 'activity':
			return <ActivityIcon />;
		case 'hotel':
			return <HotelIcon />;
		case 'package':
		default:
			return <PackageIcon />;
	}
};

export const openNotification = ({
	notificationData,
	clearNotification,
	iconWrapper: IconWrapper
}) => {
	notification.success({
		message: notificationData.msg,
		onClose: () => clearNotification(),
		placement: 'bottomRight',
		icon: <IconWrapper>{getIconByService(notificationData.type)}</IconWrapper>,
		style: {
			borderRadius: '10px',
			boxShadow: '0px 10px 60px -10px rgb(0 0 0 / 50%)',
			background: '#f0efef',
			color: '#030f33',
			fontWeight: 'bolder',
			borderBottom: 'solid 2px #030f33'
		},
		duration: 2.5
	});
};

const getCheckoutByService = (service, id) => {
	switch (service) {
		case 'transfer':
			return getCheckoutTransfer(id);
		case 'excursion':
			return getCheckoutActivity(id);
		case 'hotel':
			return getCheckoutHotel(id);
		case 'package':
			return getCheckoutPackages(id);
		default:
			return null;
	}
};

export const parseCartData = cartData => {
	const { date_in, date_out } = getSearchQuery();
	cartData.sort((first, second) => {
		if (first.type === 'package') return -1;
		else if (second.type === 'package') return 1;
		else return 0;
	});
	let parsedData = {
		payment_id: '',
		items: cartData.map((item, idx) => {
			let parsedItem = {};
			const isHotel = item.type === 'hotel';
			const isActivity = item.type === 'excursion';
			parsedItem['type'] = item.type;
			if (isActivity) parsedItem.type = 'activity';
			if (isHotel) parsedItem['rate_id'] = item.rate.id;
			parsedItem['id'] = item.rate.item_id;
			parsedItem['preset'] = getUserCurrency().name;
			parsedItem['checkout'] = {
				...getCheckoutByService(item.type, isHotel ? item.uuid : item.rate.item_id),
				...getCheckoutCart()
			};
			return parsedItem;
		}),
		checkout_info: {
			...getCheckoutCart(),
			date_in: date_in,
			date_out: date_out
		}
	};
	return parsedData;
};
