import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

/*----- Components -----*/
import { Form } from 'antd';
/*----- Selectors -----*/
import { showPackageData } from '../../../selectors/showPackageSelector';

/*----- Service -----*/
import {
	loadPassengers,
	countPassengers,
	getId,
	checkoutHandleSubmit,
	passengersFromQuery,
	mixFields
} from '../../../../commons/checkout/CheckoutUtils';
import { getSearchQuery } from '../../../../../services/SearchService';
import {
	addCheckoutPackages,
	getCheckoutPackages
} from '../../../../../services/PackagesCheckoutService';

import PackagesArrival from '../../../../commons/checkout/PackagesForms/PackagesArrival';

import moment from 'moment';
import { addToCartHandler } from '../../../../../utilities/utils';
import { setNotification } from '../../../../home/HomeActions';
import { addToCart } from '../../../../cart/actions/cartActions';
import ReservationCheckout from '../../../../commons/checkout/ReservationCheckout';

const PackagesForm = ({
	history,
	form,
	showPackages,
	withHotel,
	addToCart,
	setNotification
}) => {
	const { t } = useTranslation();
	const [continueReservation, setContinueReservation] = useState(false);
	let id = getId(history);
	const dataCheckout = getCheckoutPackages(id);
	const searchQuery = getSearchQuery();

	let passengersRequired = searchQuery.rooms
		? passengersFromQuery(searchQuery.rooms)
		: {
				adults_number: searchQuery.adults_number,
				children_ages: searchQuery.children_ages
		  };

	const passengers = countPassengers(passengersRequired);

	const parseCheckoutInfo = data => ({
		observation: data.observation || '',
		passengers: [],
		reservation_date_in: moment(searchQuery.date_in).format('DD/MM/YYYY'),
		preset: searchQuery.preset,
		meeting: data.meeting,
		lodging: {},
		searchQuery: searchQuery,
		...mixFields(true, data, 'mix')
	});

	const setPassengersParsed = data => {
		let checkoutInfo = parseCheckoutInfo(data);
		checkoutInfo.passengers = loadPassengers(data, passengers);
		addCheckoutPackages(id, checkoutInfo);
	};

	const packageCartHandler = data => {
		const parsedData = parseCheckoutInfo(data);
		let service = { ...showPackages, searchQuery: getSearchQuery() };
		service.currency = service.rate.currency;
		service.type = 'package';
		if (searchQuery.with_hotel) service.itemsDetails = dataCheckout.item_details;
		addToCartHandler(
			parsedData,
			service,
			addToCart,
			history,
			() => addCheckoutPackages(id, parsedData),
			() =>
				setNotification({
					msg: t('general.packageAddCart'),
					type: 'package'
				})
		);
	};

	return (
		<Form
			onSubmit={event => {
				checkoutHandleSubmit(
					event,
					form,
					history,
					'packages',
					id,
					setPassengersParsed
				);
			}}
		>
			<PackagesArrival
				form={form}
				withHotel={withHotel}
				dataCheckout={dataCheckout}
			/>
			<ReservationCheckout
				continueReservation={continueReservation}
				form={form}
				id={id}
				checkoutHandleSubmit={checkoutHandleSubmit}
				addToCartHandler={formData => packageCartHandler(formData)}
				setContinueReservation={setContinueReservation}
				passengersRequired={passengersRequired}
				dataCheckout={dataCheckout}
				setPassengersParsed={setPassengersParsed}
				passengers={passengers}
				service="packages"
			/>
		</Form>
	);
};

const mapStateToProps = state => {
	return {
		showPackages: showPackageData(state)
	};
};

const mapDispatchToProps = dispatch => {
	return {
		addToCart: payload => addToCart(dispatch, payload),
		setNotification: payload => setNotification(dispatch, payload)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Form.create({ name: 'checkout_packages_form' })(withRouter(PackagesForm)));
