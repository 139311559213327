import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledText, StyledTitle, DescriptionContainer } from './styles';

const HotelDescription = ({ description }) => {
	const { t, i18n } = useTranslation();

	if (!description) {
		return (
			<DescriptionContainer>
				<StyledText content={t('general.knowMore')} />
			</DescriptionContainer>
		);
	}

	const currentLanguage = i18n.language;

	const languageRegex = {
		es: /ESPAÑOL:([\s\S]+?)(?=PORTUGUÉS:|INGLÉS:|$)/,
		'pt-BR': /PORTUGUÉS:([\s\S]+?)(?=ESPAÑOL:|INGLÉS:|$)/,
		en: /INGLÉS:([\s\S]+?)(?=ESPAÑOL:|PORTUGUÉS:|$)/
	};

	const languageText = description.match(languageRegex[currentLanguage]);

	const textToDisplay = languageText
		? languageText[1].trim()
		: t('general.knowMore');

	return (
		<DescriptionContainer>
			<StyledTitle content={t('general.knowMore')} size={3} />
			<StyledText content={textToDisplay} />
		</DescriptionContainer>
	);
};

export default HotelDescription;
